@import '../../../styles/colors';
@import '../../../styles/common';
@import '../../../styles/shared';
@import '../../../styles/variables';
@import '~@andes/button/index';
@import '~@andes/modal/index';
@import '~@andes/snackbar/index';
@import 'eshops-components-library/src/components/ProfileHeader/lib/desktop';
@import '../../../components/EmptyState/styles.scss';
@import '../../../components/Header/styles';
@import '../../../components/ListsComponent/styles';
@import '../../../components/TitleList/styles';
@import '../../../components/DenunceProfile/styles';
@import '../../../components/MainTitle/styles';

.home-lists {
  display: flex;
  justify-content: center;

  &_container {
    display: flex;
    flex-direction: column;
    width: 1180px;
    margin-top: 47px;
    gap: 24px;
    &:has(.empty-state) {
      min-width: 1180px;
      align-items: normal;
    }
  }

  .andes-carousel-snapped__container--content.andes-carousel-snapped__container--with-controls.andes-carousel-snapped__container--strict-boundaries {
    margin: 0;
    width: 100%;
  }

  .andes-carousel-snapped__slide {
    height: auto;
  }

  .andes-carousel-snapped__control {
    top: 48%;
  }
}
