@import '~@andes/typography/index';

.modal-component {
  .andes-modal__title {
    font-size: $font-size-20;
  }
  &--mobile {
    .andes-modal__actions {
      display: flex;
      flex-direction: column;
    }

    span {
      display: flex;
      text-align: center;
    }
  }
}
