@import '../styles/colors';
@import '../styles/variables';
@import '~@andes/common/index';
@import '~@andes/pagination/index';

[role='main'],
body {
  background: $background-regular-color;
}

h1 {
  margin: 0;
}

.ml-labs-logo {
  background-image: url('https://http2.mlstatic.com/resources/frontend/commons-frontend/melilab/tube.png');
  background-repeat: no-repeat;
  height: 43px;
  left: 125px;
  margin: 0;
  padding-top: 26px;
  position: absolute;
  text-indent: 24px;
  top: 5px;
  width: 33px;
}

.breadcrumb {
  display: flex;
}

.section-blocked {
  height: 100% !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 1020 !important;
  background-color: rgba($andes-white, 0.9);

  & .andes-progress-indicator-circular__label {
    font-family: 'Proxima Nova' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    color: $andes-text-color-secondary !important;
  }
}

@media (width >=768px) {
  .page-wrapper {
    margin-top: 32px !important;
  }
}

html {
  /* Improve scrolling in modals and dropdowns */
  overflow: hidden auto !important;
}
