@import '~@andes/typography/index';

.main-title {
  &_container {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &_link {
    text-decoration: none;
  }
}
