@import '~@polycard/phrase/build';
@import '~@polycard/card/build';
@import '~@andes/card/index';
@import '~@andes/carousel-snapped/index';
@import '~@andes/money-amount/index';
@import '~@andes/progress-indicator-circular/index';
@import '../ModalComponent/styles';

.#{$class-prefix}polycard__container {
  position: relative;
  display: flex;
  &__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $andes-spacing-16;
    width: 100%;
    height: 100%;
    background-color: $andes-gray-250;
    z-index: 1;
  }

  &-mobile {
    width: 100%;
  }
}

.#{$class-prefix}polycard__loading-webview {
  position: relative;
  height: 94px;
  min-height: 94px;
  background-color: $andes-bg-secondary;
}

.#{$class-prefix}lists__container {
  display: flex;
  flex-wrap: wrap;
  column-gap: $andes-spacing-16;
}

.#{$class-prefix}lists__container-carousel {
  display: flex;
  flex-wrap: wrap;
  column-gap: $andes-spacing-16;
  max-width: 1180px;
  gap: 24px;
}
