@import '~@andes/card/index';
@import '~@andes/typography/index';
@import '~@andes/badge/index';

.ui-denounce-profile_content {
  display: flex;
  align-items: center;
  gap: $andes-spacing-8;

  .andes-badge--small.andes-badge--pill-icon,
  svg {
    height: $andes-spacing-12 !important;
    min-width: $andes-spacing-12;
    width: $andes-spacing-12 !important;
  }

  &-hr {
    border: 0.1px solid $andes-gray-250;
    height: 15px;
    margin: 0;
  }
}
