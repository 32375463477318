// We need to use semantic colors whenever possible and Industry-specific colors only when needed.

// Semantic colors denote standard value states (such as good, bad, or warning). Each color has the same basic meaning in all contexts.
// Semantic colors are basically supported by nearly all input controls

// Industry-specific colors reflect the color conventions in a line of business or industry. The meaning of each color depends on the business context.
// Industry-specific colors are only supported by a few UI elements.

// Everything needs to be alphabetically ordered.

// Basic colors
$andes-black: #000 !default;
$andes-white: #fff !default;

// Colors
$andes-blue-base: rgb(65 137 230) !default;
$andes-blue-100: rgba($andes-blue-base, 0.1) !default;
$andes-gray-070-solid: #ededed;
$andes-gray-100: rgba($andes-black, 0.1) !default;
$andes-gray-550: rgba($andes-black, 0.55);
$black-light: #333;
$black-transparent: rgb(0 0 0 / 12.2%);
$black-dark: #000000e5;
$black: #000;
$blue-andes: #3483fa;
$blue-light: #38f;
$blue: #00f;
$green: #00a650;
$grey-clear: #e5e5e5;
$grey-dark: rgb(0 0 0 / 55%);
$grey-dim: #6b6b6b;
$grey-light: #d3d3d3;
$grey-transparent--clear: rgb(0 0 0 / 10.2%);
$grey-transparent--darker: rgb(0 0 0 / 90%);
$grey-transparent--dim: rgb(0 0 0 / 25%);
$grey-transparent: rgb(0 0 0 / 4%);
$grey: #8c8c8c;
$orange: #ffa500;
$white-dark: #f5f5f5;
$white-medium: #ededed;
$white: #fff;

// Semantic colors
$background-default-color: $white;
$background-disabled-color: $white-dark;
$background-regular-color: $white-medium;
$divider-color: $grey-transparent--clear;
$input-disabled-color: $white-dark;
$input-disabled-text-color: $grey-transparent--dim;
$input-enabled-color: $grey-transparent--darker;
$input-placeholder-color: $grey-clear;
$link-information-color: $blue-light;
$shadow: $black-transparent;
$tag-warning-color: $orange;

// Industry-specific colors
$andes-blue: $blue-andes;
$andes-checkbox-label-color: $grey-dim;
$andes-text-color-primary: $black-dark;
$andes-text-color-secondary: $grey-dark;
$andes-text-color-positive: $green;
$box-shadow-color: $black-transparent;
$container-input--boxshadow: $grey-transparent--dim;
$container-input-border--disabled: $grey-transparent--dim;
$form-container-background-color: $white;
$link-detail-color: $grey-dim;
$link-empty-background-color: $grey-transparent;
$span-download-color: $blue-light;
$span-download-hover-color: $blue;
$user-data-component-card-border-color: $white-dark;
