@import '~@andes/typography/index';

.title-list {
  &_container {
    display: flex;
    align-items: baseline;
    gap: 10px;
    width: 100%;
  }

  &_link {
    text-decoration: none;
  }
}
