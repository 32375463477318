@import '../../styles/colors';

.empty-state {
  display: flex;
  align-items: center;
  border-radius: 6px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  min-height: 379px;
  text-align: center;

  &__text-content {
    .empty-state__subtitle {
      margin: 8px 0px 16px 0 !important;
    }
  }
}
